import React from 'react'
import ContactForm from '../../Components/ContactForm/ContactForm'
import Faq from '../../Components/FAQ/Faq'

function Contact() {
  return (
    <div>
      <ContactForm/>
      <Faq/>
    </div>
  )
}

export default Contact
