const fb = require("../assets/images/Social/fb.png");
const instagram = require("../assets/images/Social/insta.png");
const linkedin = require("../assets/images/Social/linkedin.png");
const dsw = require("../assets/images/partner/dsw.png");
const ynsd = require("../assets/images/partner/ynsd.png");
const amrfe = require("../assets/images/partner/amref.png");
const empower = require("../assets/images/navAssets/empower.png");
const inclusive = require("../assets/images/increaseteam.png");
const eng = require("../assets/images/communityEng.png");
const Integrity = require("../assets/images/Integrity.png");
const Sustainability = require("../assets/images/Sustainability.png");
const youtube = require("../assets/images/Social/youtube.png");
const youthDate = require("../assets/images/event/internationalYouth.png");
const aidsDate = require("../assets/images/event/aids.jpg");
const edu = require("../assets/images/programArea/edu.png");
const women = require("../assets/images/programArea/women.png");
const womenEmpower = require("../assets/images/programArea/women.jpg");
const empowerImg = require("../assets/images/programArea/empower.png");
const humanIssue = require("../assets/images/programArea/homeIssue.png");
const peace = require("../assets/images/programArea/pease.png");
const digital = require("../assets/images/programArea/digtal.jpg");
const humanity = require("../assets/images/programArea/humanitys.jpg");
const peaceHero = require("../assets/images/programArea/pec.jpg");
const eduHero = require("../assets/images/programArea/edu.jpg");
const washHero = require("../assets/images/programArea/wash.jpg");
const envHero = require("../assets/images/programArea/env.jpg");
const empowerHero = require("../assets/images/programArea/power.jpg");
const boardImage1 = require("../assets/images/board/board1.jpg");
const boardImage2 = require("../assets/images/board/board2.png");
const boardImage3 = require("../assets/images/board/board3.png");
const boardImage4 = require("../assets/images/board/board4.png");
const boardImage5 = require("../assets/images/board/board5.png");
const boardImage6 = require("../assets/images/board/board6.png");
const boardImage7 = require("../assets/images/board/board7.png");
const boardImage8 = require("../assets/images/board/board8.png");
const boardImage9 = require("../assets/images/board/board9.png");
const advisoryBoard1 = require("../assets/images/advisory/Mistir.jpg");
const advisoryBoard2 = require("../assets/images/advisory/MR Feyera Assefa.jpg");
const advisoryBoard3 = require("../assets/images/advisory/DR yeshiteila hailu.jpg");
const advisoryBoard4 = require("../assets/images/advisory/Tewodros.png");
const advisoryBoard5 = require("../assets/images/advisory/Dawit.png");
const telegram = require("../assets/images/Social/telegram.png");
const google = require("../assets/images/Social/google.png");

const gallery1 = require("../assets/images/gallery/gallery (1).jpg");
const gallery2 = require("../assets/images/gallery/gallery (2).jpg");
const gallery3 = require("../assets/images/gallery/gallery (3).jpg");
const gallery4 = require("../assets/images/gallery/gallery (4).jpg");
const gallery5 = require("../assets/images/gallery/gallery (5).jpg");
const gallery6 = require("../assets/images/gallery/gallery (6).jpg");
const gallery7 = require("../assets/images/gallery/gallery (7).jpg");
const gallery8 = require("../assets/images/gallery/gallery (8).jpg");
const gallery9 = require("../assets/images/gallery/gallery (9).jpg");
const gallery11 = require("../assets/images/gallery/gallery (11).jpg");
const gallery12 = require("../assets/images/gallery/gallery (12).jpg");
const gallery10 = require("../assets/images/gallery/gallery (10).jpg");
const gallery13 = require("../assets/images/gallery/gallery (13).jpg");
const gallery14 = require("../assets/images/gallery/gallery (14).jpg");
export const socialMedia = [
  {
    key: 1,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi bi-facebook"
        viewBox="0 0 16 16"
        className="size-5"
      >
        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
      </svg>
    ),
    name: "Facebook",
    link: "",
  },
  {
    key: 2,
    icon: (
      <svg
        fill="currentColor"
        viewBox="0 0 32 32"
        className="size-5"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <title>telegram</title>{" "}
          <path d="M22.122 10.040c0.006-0 0.014-0 0.022-0 0.209 0 0.403 0.065 0.562 0.177l-0.003-0.002c0.116 0.101 0.194 0.243 0.213 0.403l0 0.003c0.020 0.122 0.031 0.262 0.031 0.405 0 0.065-0.002 0.129-0.007 0.193l0-0.009c-0.225 2.369-1.201 8.114-1.697 10.766-0.21 1.123-0.623 1.499-1.023 1.535-0.869 0.081-1.529-0.574-2.371-1.126-1.318-0.865-2.063-1.403-3.342-2.246-1.479-0.973-0.52-1.51 0.322-2.384 0.221-0.23 4.052-3.715 4.127-4.031 0.004-0.019 0.006-0.040 0.006-0.062 0-0.078-0.029-0.149-0.076-0.203l0 0c-0.052-0.034-0.117-0.053-0.185-0.053-0.045 0-0.088 0.009-0.128 0.024l0.002-0.001q-0.198 0.045-6.316 4.174c-0.445 0.351-1.007 0.573-1.619 0.599l-0.006 0c-0.867-0.105-1.654-0.298-2.401-0.573l0.074 0.024c-0.938-0.306-1.683-0.467-1.619-0.985q0.051-0.404 1.114-0.827 6.548-2.853 8.733-3.761c1.607-0.853 3.47-1.555 5.429-2.010l0.157-0.031zM15.93 1.025c-8.302 0.020-15.025 6.755-15.025 15.060 0 8.317 6.742 15.060 15.060 15.060s15.060-6.742 15.060-15.060c0-8.305-6.723-15.040-15.023-15.060h-0.002q-0.035-0-0.070 0z"></path>{" "}
        </g>
      </svg>
    ),
    name: "Telegram",
    link: "https://t.me/newchapterethiopia",
  },
  {
    key: 3,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-linkedin"
        className="size-5"
        viewBox="0 0 16 16"
      >
        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
      </svg>
    ),
    name: "Linkedin",
    link: "https://www.linkedin.com/in/new-chapter-youth-and-community-development-organization-2539762b0/",
  },
  {
    key: 4,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-google"
        viewBox="0 0 16 16"
        className="size-5"
      >
        <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
      </svg>
    ),
    name: "Gmail",
    link: "mailto:chapternew85@gmail.com",
  },
];
export const DeveloperSocial = [
  {
    key: 1,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi bi-facebook"
        viewBox="0 0 16 16"
        className="size-4"
      >
        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
      </svg>
    ),
    name: "Facebook",
    link: "",
  },
  {
    key: 2,
    icon: (
      <svg
        fill="currentColor"
        viewBox="0 0 32 32"
        className="size-4"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <title>telegram</title>{" "}
          <path d="M22.122 10.040c0.006-0 0.014-0 0.022-0 0.209 0 0.403 0.065 0.562 0.177l-0.003-0.002c0.116 0.101 0.194 0.243 0.213 0.403l0 0.003c0.020 0.122 0.031 0.262 0.031 0.405 0 0.065-0.002 0.129-0.007 0.193l0-0.009c-0.225 2.369-1.201 8.114-1.697 10.766-0.21 1.123-0.623 1.499-1.023 1.535-0.869 0.081-1.529-0.574-2.371-1.126-1.318-0.865-2.063-1.403-3.342-2.246-1.479-0.973-0.52-1.51 0.322-2.384 0.221-0.23 4.052-3.715 4.127-4.031 0.004-0.019 0.006-0.040 0.006-0.062 0-0.078-0.029-0.149-0.076-0.203l0 0c-0.052-0.034-0.117-0.053-0.185-0.053-0.045 0-0.088 0.009-0.128 0.024l0.002-0.001q-0.198 0.045-6.316 4.174c-0.445 0.351-1.007 0.573-1.619 0.599l-0.006 0c-0.867-0.105-1.654-0.298-2.401-0.573l0.074 0.024c-0.938-0.306-1.683-0.467-1.619-0.985q0.051-0.404 1.114-0.827 6.548-2.853 8.733-3.761c1.607-0.853 3.47-1.555 5.429-2.010l0.157-0.031zM15.93 1.025c-8.302 0.020-15.025 6.755-15.025 15.060 0 8.317 6.742 15.060 15.060 15.060s15.060-6.742 15.060-15.060c0-8.305-6.723-15.040-15.023-15.060h-0.002q-0.035-0-0.070 0z"></path>{" "}
        </g>
      </svg>
    ),
    name: "Telegram",
    link: "https://t.me/IX_Technologies",
  },
  {
    key: 3,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-linkedin"
        className="size-4"
        viewBox="0 0 16 16"
      >
        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
      </svg>
    ),
    name: "Linkedin",
    link: "https://www.linkedin.com/in/new-chapter-youth-and-community-development-organization-2539762b0/",
  },
];

export const FooterLinksData = [
  {
    key: 1,
    name: "Home",
    link: "/",
  },
  {
    key: 2,
    name: "Who We Are",
    link: "/whoweare",
  },
  {
    key: 3,
    name: "News",
    link: "/blog",
  },
  {
    key: 3,
    name: "FAQ",
    link: "/faq",
  },
];

export const FooterSupportData = [
  {
    key: 1,
    name: "FAQ",
    link: "/FAQ",
  },
];

export const MemberCategory = [
  {
    title: "Individual membership",
  },
  {
    title: "Student membership",
  },
  {
    title: "Associate membership",
  },
  {
    title: "Honorary membership",
  },
  {
    title: "Group membership ",
  },
];

export const programArea = [
  {
    title: "Young Girls and Women Empowerment",
    subMoto:
      "Illuminate Her Path: Empowering Young Women with Knowledge, Courage, and the Belief that They Can Achieve Anything.",
    image: womenEmpower,
    icon: women,
    mission: `At the heart of our mission is the commitment to empower young girls and women with the tools, skills, and resources necessary to thrive in all aspects of life. We envision a world where every girl's dreams are realized, and every woman is equipped to lead and contribute positively to her community.`,
    keyArea: [
      {
        title: "Education",
        sub: "We believe education is a fundamental right. Our program focuses on providing access to quality education, ensuring that young girls have the knowledge and skills to pursue their aspirations.",
      },
      {
        title: "Skills Development",
        sub: "The well-being of young girls and women is paramount. Our initiatives encompass health awareness campaigns, access to healthcare resources, and programs that promote mental and physical well-being.",
      },
      {
        title: "Health and Well-being",
        sub: "The well-being of young girls and women is paramount. Our initiatives encompass health awareness campaigns, access to healthcare resources, and programs that promote mental and physical well-being.",
      },
      {
        title: "Community Involvement",
        sub: "We encourage young girls and women to actively engage in their communities. Through community projects and outreach, we instill a sense of responsibility and leadership, fostering a culture of collaboration.",
      },
    ],
    gallery: [],
    catAction: [
      {
        header: "",
        empower: `Take the first step towards empowerment. Join us in our mission to uplift and inspire. Support the Young Girls and Women Empowerment Program today.`,
      },
    ],
  },
  {
    title: "Youth Empowerment",
    subMoto:
      "Youth Unleashed: Building Leaders, Inspiring Change, Shaping a Bright Tomorrow",
    image: empowerHero,
    icon: empowerImg,
    mission: `Our mission is to inspire and equip young individuals with the skills, knowledge, and resources necessary to become confident, responsible, and proactive members of their communities. We believe that investing in youth empowerment is an investment in a brighter, more inclusive future.`,
    keyArea: [
      {
        title: "Education and Skill Development",
        sub: "We believe education is a fundamental right. Our program focuses on providing access to quality education, ensuring that young girls have the knowledge and skills to pursue their aspirations.",
      },
      {
        title: "Leadership Development",
        sub: "The well-being of young girls and women is paramount. Our initiatives encompass health awareness campaigns, access to healthcare resources, and programs that promote mental and physical well-being.",
      },
      {
        title: "CMental Health and Well-being",
        sub: "We encourage young girls and women to actively engage in their communities. Through community projects and outreach, we instill a sense of responsibility and leadership, fostering a culture of collaboration.",
      },
      {
        title: "Entrepreneurship and Innovation",
        sub: "We encourage entrepreneurial thinking and innovation, providing youth with the resources and support needed to turn their creative ideas into reality.",
      },
    ],
    gallery: [],
    catAction: {
      header: "",
      empower: `Take the first step towards empowering the youth. Join us in our mission to inspire and equip young individuals for success and positive community impact.`,
    },
  },
  {
    title: "Humanitarian Resource and Emerging issue",
    image: humanity,
    icon: humanIssue,
    subMoto: "Humanity first, resourcefulness always",
    mission: `Our mission is to inspire and equip young individuals with the skills, knowledge, and resources necessary to become confident, responsible, and proactive members of their communities. We believe that investing in youth empowerment is an investment in a brighter, more inclusive future.`,
    keyArea: [
      {
        title: "Leadership Development",
        sub: "We nurture leadership skills through workshops, mentorship programs, and experiential learning opportunities, preparing young individuals to take on active roles in their communities.",
      },
      {
        title: "Education and Career Guidance",
        sub: "Our program provides resources and guidance for educational pursuits and career planning, ensuring that youth have the information they need to make informed decisions about their future.",
      },
      {
        title: "Community Engagement",
        sub: "Through community projects and volunteer opportunities, we encourage youth to actively participate in addressing local issues and contributing to positive change.",
      },
      {
        title: "Well-being and Mental Health",
        sub: "We prioritize the holistic well-being of young individuals by offering resources and support for mental health, self-care, and resilience-building.        ",
      },
    ],
    gallery: [],
    catAction: {
      header: "",
      empower: `Join us in creating a brighter future. Support the Youth Empowerment Program and be a part of empowering the next generation of leaders.`,
    },
  },
  {
    title: "Peace Building and Advocacy",
    image: peaceHero,
    icon: peace,
    subMoto: "Building bridges, empowering voices, advocating for peace.",
    mission: `Our mission is to promote peace and justice by providing resources, facilitating dialogue, and advocating for policies that address the root causes of conflicts. We believe in the power of individuals and communities to be catalysts for positive change.`,
    keyArea: [
      {
        title: "Conflict Resolution",
        sub: "We actively engage in conflict resolution efforts, facilitating dialogues and mediating to build bridges between conflicting parties and promote understanding.",
      },
      {
        title: "Advocacy for Human Rights",
        sub: "Our program advocates for the protection and promotion of human rights, working to address systemic issues and create a more just and equitable society.",
      },
      {
        title: "Community Dialogue Initiatives",
        sub: "We organize community dialogues, bringing together diverse voices to discuss issues, build understanding, and promote peaceful coexistence.",
      },
      {
        title: "Education for Peace",
        sub: "Through educational programs, we empower individuals with the knowledge and skills needed to contribute to peacebuilding efforts within their communities.",
      },
    ],
    gallery: [],
    catAction: {
      header: "",
      empower: `Stand for peace. Advocate for justice. Be a part of our Peace Building and Advocacy Program. Together, let's build a world where every individual can live in dignity, harmony, and justice.`,
    },
  },
  {
    title: "Education",
    image: eduHero,
    icon: edu,
    subMoto: "Everyone deserves the power of education",
    mission: `Our mission is to transform lives through education by promoting inclusivity, providing educational resources, and creating an environment that nurtures curiosity and lifelong learning.`,
    keyArea: [
      {
        title: "Access to Education",
        sub: " We work towards ensuring that every individual, regardless of background or circumstances, has access to quality education.",
      },
      {
        title: "Educational Resources",
        sub: "Our program provides essential educational resources, including textbooks, learning materials, and technology, to support effective teaching and learning.",
      },
      {
        title: "Teacher Training",
        sub: "We invest in the professional development of educators, providing training programs to enhance teaching methodologies, create engaging classrooms, and promote student-centered learning.",
      },
      {
        title: "Community Learning Centers",
        sub: "Establishing community learning centers to bring education closer to underserved communities and provide a supportive environment for learning.",
      },
    ],
    gallery: [],
    catAction: {
      header: "",
      empower: `Empower through education. Support NCYCDO's Education Program and contribute to building a future where knowledge is a gateway to opportunity and prosperity.`,
    },
  },
  {
    title: "Wash",
    image: washHero,
    subMoto: "Clean water, clean hands, healthy lives",
    icon: "https://media.istockphoto.com/vectors/man-washing-hands-icon-vector-id992100242?k=6&m=992100242&s=612x612&w=0&h=MJ79m1OPYA-CGDOJunChYnQKWCSNvkh5gjjIzg4_OL0=",
    mission: `Our mission is to improve the well-being of communities by providing sustainable access to clean water, promoting proper sanitation, and fostering hygiene education to prevent waterborne diseases.`,
    keyArea: [
      {
        title: "Clean Water Access",
        sub: "We strive to provide communities with reliable and sustainable access to clean and safe drinking water, reducing the risk of waterborne diseases.",
      },
      {
        title: "Sanitation Facilities",
        sub: "Our program works towards improving sanitation infrastructure, including the construction of latrines and waste management systems, ensuring dignified and hygienic living conditions.",
      },
      {
        title: "Hygiene Education",
        sub: "We conduct hygiene education programs, teaching communities about the importance of proper hygiene practices to prevent the spread of diseases and enhance overall health.",
      },
      {
        title: "Community Empowerment",
        sub: "Through community engagement and participatory approaches, we empower communities to take ownership of their water and sanitation facilities, ensuring long-term sustainability.",
      },
    ],
    gallery: [],
    catAction: {
      header: "",
      empower: `Support NCYCDO's WASH Program and be a catalyst for positive change. Every drop counts in building healthier, more resilient communities.`,
    },
  },
  {
    title: "Environmental protection and climate change",
    image: envHero,
    subMoto: "Change course, protect our environment, it's our only home",
    icon: "https://th.bing.com/th/id/R.bed29fd3624b890699a27964aa5e3c47?rik=nTU8L1dvVjYptg&pid=ImgRaw&r=0",
    mission: `Our mission is to protect and preserve the environment, address climate change, and engage communities in sustainable practices to ensure a harmonious coexistence between people and the planet.`,
    keyArea: [
      {
        title: "Climate Action",
        sub: "We work towards mitigating climate change by promoting renewable energy, sustainable practices, and advocating for policies that reduce greenhouse gas emissions.",
      },
      {
        title: "Biodiversity Conservation",
        sub: "Our program focuses on preserving and restoring biodiversity, protecting ecosystems, and supporting initiatives that promote the coexistence of diverse species.",
      },
      {
        title: "Community Resilience",
        sub: "We empower communities to adapt to the impacts of climate change through awareness, training, and the implementation of sustainable practices that enhance resilience.",
      },
      {
        title: "Environmental Education",
        sub: "Through educational initiatives, we aim to raise awareness about environmental issues, inspire eco-conscious behavior, and foster a sense of responsibility towards nature.",
      },
    ],
    gallery: [],
    catAction: {
      header: "",
      empower: `Support NCYCDO's Environmental Protection and Climate Change Program and be a guardian of our planet. Together, let's create a world where nature thrives, and future generations inherit a healthy Earth.`,
    },
  },
  {
    title: "Digital Literacy",
    image: digital,
    subMoto:
      "Connecting Hearts, Inspiring Minds: Digital Literature for Social Good and Global Understanding",
    icon: "https://th.bing.com/th/id/OIP.JPhsyTgn9yDlW4eysi3HrgHaHa?rs=1&pid=ImgDetMain",
    mission: `Our mission is to empower individuals with the essential digital skills and knowledge needed to navigate the digital landscape, fostering inclusivity and ensuring that no one is left behind in the era of technology.`,
    keyArea: [
      {
        title: "Basic Digital Skills",
        sub: "We provide foundational digital skills training, covering topics such as computer literacy, internet navigation, and the use of essential software and applications.",
      },
      {
        title: "Cybersecurity Awareness",
        sub: "Our program emphasizes the importance of cybersecurity, educating individuals on safe online practices, protecting personal information, and navigating the digital world securely.",
      },
      {
        title: "Digital Inclusion",
        sub: "We strive for digital inclusivity by addressing barriers to access, ensuring that individuals from all backgrounds have the opportunity to benefit from digital resources and opportunities.        ",
      },
      {
        title: "Advanced Digital Literacy",
        sub: "Beyond the basics, our program offers advanced training in areas such as coding, digital marketing, and data literacy, empowering individuals to explore diverse opportunities in the digital realm.",
      },
    ],
    gallery: [],
    catAction: {
      header: "",
      empower: `Empower through technology. Support NCYCDO's Digital Literacy Program and contribute to building a digitally inclusive future where everyone has the skills to thrive in the digital era.      `,
    },
  },
  {
    title: "Gender Equality and Disability Inclusion",
    subMoto:
      "Equality Knows No Limits: Advocating for Gender Equality and Disability Inclusion, Creating a World Where Every Voice Counts",
    image:
      "https://th.bing.com/th/id/R.2ad8636874525afbceecae2d8c2a6ad4?rik=EbqmoXnxnLmpAQ&pid=ImgRaw&r=0",
    icon: "https://th.bing.com/th/id/R.f1db6341977ebbab903974f9199a1071?rik=w9mcWaET69efQw&pid=ImgRaw&r=0",
    mission: `Our mission is to advance gender equality and promote disability inclusion by advocating for equal rights, dismantling societal barriers, and creating spaces where every individual can participate fully and without discrimination.    `,
    keyArea: [
      {
        title: "Gender Equality Advocacy",
        sub: "We advocate for the rights and empowerment of individuals of all genders, working towards dismantling gender-based stereotypes, discrimination, and promoting equal opportunities.",
      },
      {
        title: "Disability Inclusion Initiatives",
        sub: "Our program focuses on creating inclusive environments for individuals with disabilities, advocating for accessibility, and supporting initiatives that enhance the quality of life for people with diverse abilities.",
      },
      {
        title: "Education and Awareness",
        sub: "We conduct educational programs and awareness campaigns to challenge societal norms, eliminate stigma, and foster understanding about gender equality and disability inclusion.",
      },
      {
        title: "Empowerment Through Employment",
        sub: "We promote economic empowerment by supporting initiatives that create inclusive workplaces, providing equal opportunities for individuals of all genders and abilities.",
      },
    ],
    gallery: [],
    catAction: [
      {
        header: "",
        empower: `Empower through technology. Support NCYCDO's Digital Literacy Program and contribute to building a digitally inclusive future where everyone has the skills to thrive in the digital era.      `,
      },
    ],
  },
];

export const TermsAndCondition = [
  {
    condition: "Abide by the Principles of the NCYCDO movement",
  },
  {
    condition:
      "Always act in the best interests of the NCYCDO and never do anything to bring the NCYCDO into disrepute.",
  },
  {
    condition: "Abide by the terms and conditions of the Constitution.",
  },
  {
    condition: "Treat fellow members with respect and courtesy at all times.",
  },
  {
    condition:
      "Comply with and support the decisions of the elected committee.",
  },
  {
    condition: "Advise the committee of any change in your personal details",
  },
];

export const FAQData = [
  {
    question: "What is our mission?",
    answer:
      "supporting the overall community developments throughout the country.",
  },
  {
    question: "What do we do?",
    answer:
      "We offer a range of services including mentorship, educational programs, and community outreach projects.",
  },
  {
    question: "How can I get involved?",
    answer:
      "You can get involved by volunteering, donating, or becoming a mentor. Visit our website for more information.",
  },
  {
    question: "How can I get involved?",
    answer:
      "You can get involved by volunteering, donating, or becoming a mentor. Visit our website for more information.",
  },
  {
    question: "How can I donate?",
    answer:
      "You can donate online through our website or contact us directly for other donation options.",
  },
  {
    question: "How can I become a mentor?",
    answer:
      "To become a mentor, fill out our online application form and we will get in touch with you.",
  },
  {
    question: "How can I support? ",
    answer:
      "You can support our organization by spreading awareness about our mission, attending our events, making a donation, or volunteering your time and skills. Together, we can make a difference.",
  },
];

export const PartnersWithUs = [
  {
    name: "YNSDA",
    link: "https://www.ynsdethiopia.org.et/",
    logo: ynsd,
  },
  {
    name: "DSW",
    link: "https://www.dsw.org/en/",
    logo: dsw,
  },
  // {
  //   name: "Amref Health Africa",
  //   link: "https://amref.org/",
  //   logo: amrfe,
  // },
];

export const corValue = [
  {
    title: "Empowerment",
    icon: empower,
    link: "https://amref.org/",
    value:
      "Encouraging individuals to take control of their lives and make positive choices.",
  },
  {
    title: "Inclusively",
    icon: inclusive,
    link: "https://amref.org/",
    value:
      "Our programs and initiatives are inclusive, embracing diversity in all its forms.",
  },
  {
    title: "Community Engagement",
    icon: eng,
    link: "https://amref.org/",
    value:
      "Actively involving and collaborating with the community to identify needs and implement effective solutions.",
  },
  {
    title: "Integrity",
    icon: Integrity,
    link: "https://amref.org/",
    value:
      "Our actions are guided by honesty, transparency, and a commitment to ethical practices.",
  },
  {
    title: "Accountability",
    icon: "https://th.bing.com/th/id/OIP.boBy2VPC0fgtOTHtrBnrIQHaG0?rs=1&pid=ImgDetMain",
    link: "https://amref.org/",
    value:
      "Taking responsibility for actions and ensuring transparency in organizational practices.",
  },
  {
    title: "Sustainability",
    icon: Sustainability,
    link: "https://amref.org/",
    value:
      "Promoting practices that contribute to the long-term well-being of both individuals and the community.",
  },
];

export const whyInvolveData = [
  {
    title: "Impactful Contributions",
    icon: empower,
    description:
      "Every contribution, big or small, has a ripple effect. When you get involved with us, you are contributing to projects and initiatives that directly address critical issues in our community. Your time, skills, and dedication can be the catalyst for positive change.",
  },
  {
    title: "Empowering Communities:",
    icon: empower,
    description:
      "Our work goes beyond immediate impact; it's about empowering communities to thrive. When you get involved, you play a role in creating sustainable solutions that uplift individuals and families, fostering a sense of resilience and self-reliance.",
  },
  {
    title: "Building Connections:",
    icon: empower,
    description:
      "Getting involved with NCYCDO is not just about volunteering; it's about building meaningful connections. Join a community of passionate and like-minded individuals who share your commitment to making the world a better place. Forge friendships that extend beyond volunteering hours.",
  },
  {
    title: "Making a Lasting Difference:",
    icon: empower,
    description:
      "The work we do is not only about addressing current challenges but also about creating a lasting legacy. When you get involved, you contribute to a collective effort that will leave a positive imprint on the lives of generations to come.",
  },
  {
    title: "Personal Growth and Fulfillment:",
    icon: empower,
    description:
      "Volunteering is a transformative experience. It provides an opportunity for personal growth, skill development, and a profound sense of fulfillment. The journey of getting involved is as rewarding for you as it is for those we serve.",
  },
];

export const eventData = [
  {
    image:
      "https://www.firstladiesyouthleadershipfdn.org/wp-content/uploads/2022/07/First-Ladies-Calendar-Featured-Image-Template-IWD.jpg",
    title: "International Women Day",
    venue: {
      date: `Fir 8 March 2024`,
      location: "Adama",
      time: "00:00 - 23:59",
    },
    desc: "International Women's Day is not just a day; it's a movement. This event aims to amplify women's voices, acknowledge their contributions, and foster a community committed to gender equality. Join us as we celebrate the achievements of women and pave the way for a more inclusive and empowered future.",
  },
  {
    image: youthDate,
    title: "International Youth Day",
    venue: {
      date: `Fir 12 Aug 2024`,
      location: "Adama",
      time: "00:00 - 23:59",
    },
    desc: "Join us on August 12th as we celebrate International Youth Day, a global initiative dedicated to recognizing the power and potential of young people in shaping a brighter future. This event is an opportunity to amplify youth voices, address challenges they face, and celebrate the positive impact they make in their communities and beyond.    ",
  },

  {
    image: aidsDate,
    title:
      "World AIDS Day Commemoration: 'Breaking Barriers, Building Bridges'",
    venue: {
      date: `Sun 1 Dec 2024`,
      location: "Adama",
    },
    desc: `Join us on December 1st as we mark World AIDS Day with a powerful event themed "Breaking Barriers, Building Bridges." This day serves as a global moment to unite in the fight against HIV/AIDS, raise awareness, and remember those we've lost. Our commemoration emphasizes breaking down stigma and building bridges of support for those affected by HIV/AIDS.`,
  },
  {
    image:
      "https://opa.hhs.gov/sites/default/files/content-images/NAHM%202022%20social%20graphic.png",
    title: "National Adolescent Youth Health Forum",
    venue: {
      date: `Thu 1-3 Oct 2024`,
      location: "Adama",
    },
    desc: `Join us for the National Adolescent Youth Health Forum, a dynamic and informative gathering aimed at addressing critical health issues affecting the youth. This forum brings together experts, policymakers, and young leaders to engage in meaningful discussions, share insights, and collectively work towards improving the health and well-being of adolescents.`,
  },
];

export const boardMember = [
  {
    name: "Dawit Zegeye",
    position: "Board Of Chair Man And PHD candidate and diplomat",
    image: boardImage1,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    name: "Lamrot Bzuayew",
    position: "Vice Chairman",
    image: boardImage2,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    name: "Yesunesh Takele",
    position:
      "BA Degree Adult education and community development, Advocacy, mobilization and SBC Officer",
    image: boardImage8,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    name: "Sebah Miftah",
    position: "BSc in Mental Health Professional, MA in Project management",
    image: boardImage6,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    name: "ALEMTSEHAY ADUGNA",
    position: "BA Degree in management",
    image: boardImage7,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    name: "Mintsnot Nadew",
    position: "BSc Computer Engineering Cofounder and CEO IX technologies",
    image: boardImage9,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
];

export const adviseryBord = [
  {
    name: "M/S Mistir Alebachew",
    position: "Program Manager of NEWA",
    image: advisoryBoard1,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    name: "M/R feyera Assefa",
    position: "Country Director of DSW",
    image: advisoryBoard2,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    name: "D/R Yeshitila Hailu",
    position:
      "Capacity building and Partnership Senior Advisor of amref health Africa",
    image: advisoryBoard3,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    name: "M/R Tewodros Bonsa",
    position: "Volunteer service Advisor",
    image: advisoryBoard4,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
];

export const eduBackground = [
  "Primary education",
  "Secondary education",
  "Bachelor's degree",
  "Master's degree",
  "Doctorate",
  "Higher",
];

export const galleryData = [
  {
    image: gallery1,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },

  {
    image: gallery6,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    image: gallery4,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    image: gallery2,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    image: gallery7,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    image: gallery12,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },

  {
    image: gallery3,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    image: gallery11,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    image: gallery5,
    desc: "Lorem isum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },

  {
    image: gallery8,
    desc: "Lorem isum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    image: gallery14,
    desc: "Lorem isum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    image: gallery10,
    desc: "Lorem isum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },

  {
    image: gallery13,
    desc: "Lorem isum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },
  {
    image: gallery9,
    desc: "Lorem isum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl sit amet ultricies luctus, nunc nisl aliquam ipsum, et faucibus erat arcu id mauris. ",
  },

];

export const newsCategory = ["news", "events", "Technology"];
