import React, { useEffect, useState } from "react";
import AdminPannel from "../../Components/Dashbord/DAshboard";
import axios from "axios";
import HTMLReactParser from "html-react-parser";
import * as htmlparser2 from "htmlparser2";
function AllNews() {
  const [news, setNews] = useState([]);
  const fetchNews = async () => {
    await axios
      .get("/news")
      .then((response) => {
        setNews(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchNews();
  });
  return (
    <div>
      <AdminPannel />
      <div class="w-full pt-10 px-4 sm:px-6 md:px-8 lg:ps-72">
        <div class="">
          <div class="md:grid md:grid-cols-3 md:gap-8">
            {news.map((news) => {
              return (
                <div class="rounded w-full  p-4 lg:p-0">
                  <img
                    src={news.imageBase64}
                    class="rounded"
                    alt="technology"
                  />
                  <div class="p-4 pl-0">
                    <h2 class="font-bold text-2xl text-gray-800">
                      {HTMLReactParser(news.Title)}
                    </h2>
                    <p class="text-gray-700 mt-2">
                      {HTMLReactParser(news.Content)}
                    </p>

                    <a
                      href="#"
                      class="inline-block py-2 rounded text-green-900 mt-2 ml-auto"
                    >
                      {" "}
                      Read more{" "}
                    </a>
                  </div>
                </div>
              );
            })}
            <div class="rounded w-full  p-4 lg:p-0">
              <img
                src="https://images.unsplash.com/photo-1504384764586-bb4cdc1707b0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60"
                class="rounded"
                alt="technology"
              />
              <div class="p-4 pl-0">
                <h2 class="font-bold text-2xl text-gray-800">
                  Is at purse tried jokes china ready decay an.{" "}
                </h2>
                <p class="text-gray-700 mt-2">
                  Small its shy way had woody downs power. To denoting admitted
                  speaking learning my exercise so in. Procured shutters mr it
                  feelings. To or three offer house begin taken am at.
                </p>

                <a
                  href="#"
                  class="inline-block py-2 rounded text-green-900 mt-2 ml-auto"
                >
                  {" "}
                  Read more{" "}
                </a>
              </div>
            </div>

            <div class="rounded w-full  p-4 lg:p-0">
              <img
                src="https://images.unsplash.com/photo-1483058712412-4245e9b90334?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80"
                class="rounded"
                alt="technology"
              />
              <div class="p-4 pl-0">
                <h2 class="font-bold text-2xl text-gray-800">
                  As dissuade cheerful overcame so of friendly he indulged
                  unpacked.
                </h2>
                <p class="text-gray-700 mt-2">
                  Alteration connection to so as collecting me. Difficult in
                  delivered extensive at direction allowance. Alteration put use
                  diminution can considered sentiments interested discretion.
                </p>

                <a
                  href="#"
                  class="inline-block py-2 rounded text-green-900 mt-2 ml-auto"
                >
                  {" "}
                  Read more{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllNews;
