import React from 'react'
import Faq from '../../Components/FAQ/Faq'

function FaqPage() {
  return (
    <div>
      <Faq/>
    </div>
  )
}

export default FaqPage
