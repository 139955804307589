import React from 'react'
import Auth from '../../Components/Auth/Auth'

function SuperAdmin() {
  return (
    <div>
      <Auth/>
    </div>
  )
}

export default SuperAdmin
