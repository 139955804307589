import React from 'react'
import structer from '../../assets/images/structer.png'
function OrganizationStructure() {
    return (
        <div>
            <div className='w-[84rem] m-auto'>
                <div className="text-3xl font-bold text-center my-10 dark:text-gray-50">Organization Structure</div>
                <img src={structer} />
            </div>
        </div>
    )
}

export default OrganizationStructure
