import React, { useRef, useState } from "react";
import AdminPannel from "../../Components/Dashbord/DAshboard";
import axios from "axios";
import JoditEditor from "jodit-react";
import { TagInputBox } from "tag-input-box";
import { newsCategory } from "../../Data/Data";

function NewsGenerator() {
  axios.defaults.withCredentials = true;

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [errorHandler, setErrorHandler] = useState(false);
  const [errorHandlers, setErrorHandlers] = useState("");
  const [successHandler, setSuccessHandler] = useState(false);
  const [successHandlers, setSuccessHandlers] = useState("");
  const [formDatas, setFormData] = useState({});
  const [items, setItems] = useState([]);
  const imagePreview = useRef(null);
  const [image, setImage] = useState([]);
  const [content, setContent] = useState(null);
  const [newsTitle, setNewsTitle] = useState("");
  const [buffer, setBuffer] = useState(null);
  const formRef = useRef(null);
  const handleInput = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formDatas,
      [name]: value,
    });
  };

  const uploadNews = async (e) => {
    e.preventDefault();
    console.log(image)
  

    const formData = new FormData(formRef.current);

    if(formData.Title==null||formData.Content===null||formData.newImage){
      setErrorHandler(true);
      setErrorHandlers("Please fill formData with a title and a content image");
      return
    }
    if(image.length<=0){
      setErrorHandler(true);
      setErrorHandlers("Please Select Image");
      return
    }
    formData.append("Title", newsTitle);
    formData.append("Content", content)
    formData.append("Tags", items);
    formData.append("Author", currentUser.username);

    const urlEncode = new URLSearchParams(formData);
    console.log(urlEncode);

   await axios
      .post("/news",  formData)
      .then((res) => {
        console.log(res.data);
        setSuccessHandler(true);
        setSuccessHandlers(res.data.message);
        formRef.current.reset();
      })
      .catch((err) => {
        console.log(err);
        setErrorHandler(true);
        setErrorHandlers(err.response.data.message);
      });
  };
  const onSelectImages = (event) => {
    console.log(event.target.files);
    const file = event.target.files[0];
    const image = URL.createObjectURL(file);
    imagePreview.current.src = image;
    setImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div>
      <AdminPannel />
      <div class="w-full pt-10 px-4 sm:px-6 md:px-8 lg:ps-72">
        <div class="">
          <div class="bg-white rounded-xl shadow p-4 sm:p-7 dark:bg-slate-900">
            <div class="mb-8">
              <h2 class="text-xl font-bold text-gray-800 dark:text-gray-200">
                News Title
              </h2>
              <p class="text-sm text-gray-600 dark:text-gray-400">
                Manage your name, password and account settings.
              </p>
            </div>

            <form
              method="post"
              encType="multipart/form-data"
              ref={formRef}
            >
              <div class="grid sm:grid-cols-12 gap-2 sm:gap-6">
                <div class="sm:col-span-3">
                  <label class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200">
                    News Images
                  </label>
                </div>

                <div class="sm:col-span-9">
                  <div class="flex items-center gap-5">
                    <img
                      class="inline-block size-36 object-cover ring-white dark:ring-gray-800"
                      src="https://th.bing.com/th/id/OIP.RdThRyUB739he0R_EoS-LQAAAA?rs=1&pid=ImgDetMain"
                      alt="Image Description"
                      ref={imagePreview}
                    />
                    <div class="flex gap-x-2">
                      <div>
                        <button
                          type="button"
                          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm relative cursor-pointer font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        >
                          <svg
                            class="flex-shrink-0 size-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                            <polyline points="17 8 12 3 7 8" />
                            <line x1="12" x2="12" y1="3" y2="15" />
                          </svg>
                          <input
                            type="file"
                            onChange={onSelectImages}
                            accept="image/*"
                            name="newImage"
                            id=""
                            className="opacity-0 absolute top-0 left-0 right-0 bottom-0"
                          />
                          Upload photo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label
                    for="af-account-full-name"
                    class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                  >
                    News Title
                  </label>
                  <div class="hs-tooltip inline-block">
                    <button type="button" class="hs-tooltip-toggle ms-1">
                      <svg
                        class="inline-block size-3 text-gray-400 dark:text-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                      </svg>
                    </button>
                    <span
                      class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible w-40 text-center z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700"
                      role="tooltip"
                    >
                      Displayed on public forums, such as Preline
                    </span>
                  </div>
                </div>

                <div class="sm:col-span-9">
                  <JoditEditor value={newsTitle} onChange={setNewsTitle} />
                </div>

                <div class="sm:col-span-3">
                  <label
                    for="af-account-email"
                    class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                  >
                    Subtitle
                  </label>
                </div>

                <div class="sm:col-span-9">
                  <input
                    id="af-account-email"
                    type="text"
                    class="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                    placeholder="maria@site.com"
                  />
                </div>

                <div class="sm:col-span-3">
                  <label
                    for="af-account-password"
                    class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                  >
                    Content
                  </label>
                </div>

                <div class="sm:col-span-9">
                  <div class="space-y-2">
                    <JoditEditor value={content} onChange={setContent} />
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="af-account-password"
                    class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                  >
                    Source
                  </label>
                </div>

                <div class="sm:col-span-9">
                  <div class="space-y-2">
                    <input
                      id="af-account-email"
                      type="text"
                      class="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      placeholder="NCYCDO"
                      onChange={(e) => handleInput(e)}
                      name="Source"
                    />
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="af-account-password"
                    class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                  >
                    Tag
                  </label>
                </div>

                <div class="sm:col-span-9">
                  <div class="space-y-2">
                    <TagInputBox items={items} setItems={setItems} />
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <label
                    for="af-account-password"
                    class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                  >
                    Category
                  </label>
                </div>

                <div class="sm:col-span-9">
                  <div class="mt-2">
                    <div class="grid sm:flex gap-3">
                      <select
                        name="Category"
                        onChange={(e) => handleInput(e)}
                        class="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-amber-500 focus:ring-amber-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                      >
                        {newsCategory.map((region, index) => {
                          return (
                            <option value={region.state_code}>{region}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {errorHandler ? (
                <div
                  class="p-4 mb-4 mt-4 text-sm text-red-800 rounded-lg bg-red-100 dark:bg-gray-800 dark:text-red-400"
                  role="alert"
                >
                  {errorHandlers}
                </div>
              ) : null}
              {successHandler ? (
                <div
                  class="p-4 mb-4  mt-4 text-sm text-green-800 rounded-lg bg-green-100 dark:bg-gray-800 dark:text-green-400"
                  role="alert"
                >
                  {successHandlers}
                </div>
              ) : null}
              <div class="mt-5 flex justify-end gap-x-2">
                <button
                  type="button+"
                  onClick={uploadNews}
                  class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-amber-500 text-white hover:bg-amber-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsGenerator;
