import React from "react";


function FollowUs({icon, name, link}) {
    return (
        <li>
            <a class="text-sm leading-6 text-gray-600 hover:text-gray-400 flex gap-2 items-center dark:text-gray-400" href={link} target="_blank">
                {icon }
                <span>{name}</span>
            </a>
        </li> 
    );
}

export default FollowUs;
